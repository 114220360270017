import React, { useEffect, useState } from "react";
import Banner from "./items/Banner";
import Products from "./items/Product/Products";
import { Button } from "antd";
import { PiNewspaperClippingLight } from "react-icons/pi";
import { HomeOutlined } from "@ant-design/icons";
import News from "../News/News";
import { sliderGet } from "../../api/SliderApi";
import { newsGet } from "../../api/NewsApi";
import {useTranslation} from "react-i18next"; // Предположим, что у вас есть API для получения новостей

export default function Home({ categories }) {
    const [isHome, setHome] = useState(true);
    const [sliders, setSliders] = useState([]);
    const [news, setNews] = useState([]);
    const { t } = useTranslation();
    const fetchSliders = async () => {
        const data = await sliderGet();
        setSliders(data);
    };

    const fetchNews = async () => {
        const data = await newsGet();
        console.log(data)
        setNews(data);
    };

    useEffect(() => {
        fetchSliders();
        fetchNews();
    }, []);

    return (
        <div className={'w-full h-full bg-white/50 rounded p-0 overflow-hidden relative'}>
            {isHome ? (
                <>
                    <div className={'w-full h-[185px]  p-2'}>
                        <Banner sliders={sliders} />
                    </div>
                    <div className={'w-full h-full overflow-scroll'}>
                        <Products categories={categories} />
                    </div>
                </>
            ) : (
                <div className={'w-full h-full'}>
                    <News news={news} />
                </div>
            )}
            <div className={'w-full h-[70px] absolute bottom-0 flex flex-row items-center justify-center'}>
                <Button onClick={() => setHome(true)} icon={<HomeOutlined />}>{t('home_button')}</Button>
                <Button onClick={() => setHome(false)} className={'ml-6'} icon={<PiNewspaperClippingLight />}>{t('news_button')}</Button>
            </div>
        </div>
    );
}
