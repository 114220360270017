import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Pagination } from 'swiper/modules';
import './style.css';
import { Modal } from 'antd';
import {getNewsByLanguage} from "../../../utils/utils"; // Используем модальное окно из Ant Design

export default function Banner({ sliders }) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSlide, setSelectedSlide] = useState(null);
    const BASE_URL = process.env.REACT_APP_URL;

    useEffect(() => {
        console.log(sliders);
    }, [sliders]);

    const handleSlideClick = (slide) => {
        setSelectedSlide(slide);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedSlide(null);
    };

    return (
        <div className="w-full h-[180px] flex flex-col">
            <Swiper
                pagination={true}
                modules={[Pagination]}
                className="mySwiper rounded-xl"
            >
                {getNewsByLanguage(sliders).map((value, index) => (
                    <SwiperSlide key={index} onClick={() => handleSlideClick(value)}>
                        <img
                            src={`${BASE_URL}${value.cover}`}
                            alt={`Slide ${index + 1}`}
                            className="w-full h-full object-cover rounded-xl cursor-pointer"
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            <Modal
                visible={isModalVisible}
                onCancel={handleModalClose}
                footer={null}
                centered
                className="custom-modal"
            >
                {selectedSlide && (
                    <>
                        <img
                            src={`${BASE_URL}${selectedSlide.cover}`}
                            alt={`Slide ${selectedSlide.id}`}
                            className="w-full h-[300px] object-cover mb-4 rounded-xl"
                        />
                        <p className="text-center">{selectedSlide.description}</p>
                    </>
                )}
            </Modal>
        </div>
    );
}
