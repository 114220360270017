import React, { useState, useEffect } from "react";
import {register, login, loginTelegram} from '../../api/AuthApi';
import { useTelegram } from "../../hooks/TelegramHooks";
import { message, Modal, Input, Button } from "antd";
import { createPostEvent } from '@telegram-apps/sdk';
import { initMiniApp } from '@telegram-apps/sdk';
import { LoginButton } from '@telegram-auth/react';

export default function Auth() {
    const { tg, requestContact, user } = useTelegram();
    const [isLogin, setIsLogin] = useState(true);
    const [miniApp, setMiniApp] = useState(null);
    const [formData, setFormData] = useState({
        chat_id: '',
        fullname: '',
        phoneNumber: '',
        password: '',
        address: '',
        location: '',
        telegram_id: '',
    });
    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState({});
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (user !== undefined) {
            const initializeMiniApp = async () => {
                try {
                    const [miniApp] = await initMiniApp();
                    setMiniApp(miniApp);
                } catch (error) {
                    console.error("Error initializing mini app:", error);
                }
            };
            initializeMiniApp();
        }
    }, [tg.ready]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isLogin) {
             const data  = user === undefined ? await login({phoneNumber:formData.phoneNumber,password:formData.password}):  await loginTelegram({ telegram_id: user?.id, password: formData.password });
                console.log(data.user)
                 localStorage.setItem('token',data.token)
                  localStorage.setItem('user',JSON.stringify(data.user))
                window.location.reload()

            } else {
                await register(formData);
                message.success('Registration successful');
            }
        } catch (error) {
            console.error(error.message);
            message.error('An error occurred');
        }
    };

    const handleTelegramLogin = async () => {
        if (miniApp) {
            try {
                miniApp.requestContact().then(contact => {
                    const data = contact?.contact
                    const phoneNumber = data?.phoneNumber;
                    const fullname = data?.firstName + " " + contact?.lastName;
                    const telegram_id = data?.userId;

                    setModalData({phoneNumber: phoneNumber,fullname: fullname,telegram_id: telegram_id ,chat_id : telegram_id});
                    setModalVisible(true);

                });

            } catch (error) {
                console.error("Error requesting contact:", error);
                message.error('Failed to request contact');
            }
        } else {
            message.warning('Mini app is not available');
        }
    };

    const handleModalOk = async () => {
        try {
            await register({ ...modalData, password: password });
            message.success('Registration successful');
            setModalVisible(false);
        } catch (error) {
            console.error("Error during registration:", error);
            message.error(`Error ${error}`);
        }
    };

    return (
        <div className='w-screen h-screen flex justify-center items-center bg-gray-100 p-4'>
            <div className='w-full max-w-md bg-white p-6 rounded-lg shadow-lg'>
                <h1 className='text-3xl font-semibold mb-6 text-center'>{isLogin ? 'Login' : 'Register'}</h1>
                <form onSubmit={handleSubmit} className='space-y-4'>
                    {!isLogin && (
                        <>
                            <input
                                type='text'
                                name='chat_id'
                                placeholder='Chat ID'
                                value={formData.chat_id}
                                onChange={handleChange}
                                required
                                className='w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                            />
                            <input
                                type='text'
                                name='fullname'
                                placeholder='Full Name'
                                value={formData.fullname}
                                onChange={handleChange}
                                required
                                className='w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                            />
                            <input
                                type='text'
                                name='phoneNumber'
                                placeholder='Phone Number'
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                                className='w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                            />
                            <input
                                type='text'
                                name='telegram_id'
                                placeholder='Telegram ID'
                                value={formData.telegram_id}
                                onChange={handleChange}
                                required
                                className='w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                            />
                            <input
                                type='text'
                                name='address'
                                placeholder='Address'
                                value={formData.address}
                                onChange={handleChange}
                                className='w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                            />
                            <input
                                type='text'
                                name='location'
                                placeholder='Location (JSON)'
                                value={formData.location}
                                onChange={handleChange}
                                className='w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                            />
                        </>
                    )}
                    {user === undefined && <input
                        type='text'
                        name='phoneNumber'
                        placeholder='+998 ** *** ** **'
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                        className='w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                    />}
                    <input
                        type='password'
                        name='password'
                        placeholder='Password'
                        value={formData.password}
                        onChange={handleChange}
                        required
                        className='w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                    />
                    <button
                        type='submit'
                        className='w-full p-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500'
                    >
                        {isLogin ? 'Login' : 'Register'}
                    </button>
                </form>
                <div className='mt-4 text-center w-full'>
                    {miniApp && <button
                        onClick={handleTelegramLogin}
                        className='w-full bg-gray-800 text-white p-3 rounded-md hover:bg-gray-700'
                    >
                        Request Contact from Telegram
                    </button>}
                    {user === undefined && <LoginButton
                        botUsername={'LotusHeroBot'}
                        onAuthCallback={(data) => {
                            console.log(data);
                            // call your backend here to validate the data and sign in the user
                        }}
                    />}
                </div>
                <button
                    onClick={() => setIsLogin(!isLogin)}
                    className='mt-4 text-blue-500 block mx-auto'
                >
                    {isLogin ? 'Need an account? Register' : 'Already have an account? Login'}
                </button>
            </div>

            {/* Modal for contact info and password */}
            <Modal
                title="Confirm Registration"
                visible={modalVisible}
                onOk={handleModalOk}
                onCancel={() => setModalVisible(false)}
                okText="Register"
                cancelText="Cancel"
            >
                <p><strong>Phone Number:</strong> {modalData.phoneNumber}</p>
                <p><strong>Full Name:</strong> {modalData.fullname}</p>
                <p><strong>Telegram ID:</strong> {modalData.telegram_id}</p>
                <Input
                    type='password'
                    placeholder='Set Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className='mt-4'
                />
            </Modal>
        </div>
    );
}
