import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { motion } from "framer-motion";
import card_bg from '../../assets/img/backgrounds/card_item.png';
import { productFindById, upDateBasket } from "../../utils/utils";
import { FaPlus, FaMinus } from "react-icons/fa";

export default function Card_item({ card }) {
    const BASE_URL = process.env.REACT_APP_URL;
    const [count, setCount] = useState(0);

    const updateCount = () => {
        const product = productFindById(card.id);
        setCount(product?.count || 0);
    };

    useEffect(() => {
        updateCount();
        const handleStorageChange = () => {
            updateCount();
        };
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [card.id]);

    const add = (id) => {
        upDateBasket('add', id, 1);
        updateCount();
    };

    const remove = (id) => {
        if (count > 0) {
            upDateBasket('remove', id, 1);
            updateCount();
        }
    };

    return (
        <motion.div
            className="w-full h-[160px] rounded-md flex flex-row bg-cover bg-center"
            style={{ backgroundImage: `url(${card_bg})` }}
            initial={{ scale: 1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
        >
            <img className="w-[80px] h-[110px] ml-8 mt-2" src={`${BASE_URL}${card.cover[0]}`} alt={card.name} />

            <div className="flex flex-col ml-6 mt-2 items-center justify-center">
                <Typography className="font-bold text-xl p-1 rounded text-white">
                    {card.name}
                </Typography>
                <div className="flex items-center mt-4">
                    <motion.button
                        className="flex items-center justify-center mx-2 p-2 w-[40px] h-[30px] bg-gradient-to-r from-red-500 to-red-700 text-white rounded-full hover:from-red-600 hover:to-red-800 transition-transform"
                        onClick={() => remove(card.id)}
                        disabled={count <= 0}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <FaMinus className="text-lg" />
                    </motion.button>
                    <Typography className="font-bold text-[12px] p-1 rounded text-white">
                        {count}
                    </Typography>
                    <motion.button
                        className="flex items-center justify-center mx-2 p-2 w-[40px] h-[30px] bg-gradient-to-r from-green-500 to-green-700 text-white rounded-full hover:from-green-600 hover:to-green-800 transition-transform"
                        onClick={() => add(card.id)}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <FaPlus className="text-lg" />
                    </motion.button>
                </div>
            </div>
        </motion.div>
    );
}
