import api from './api';
export const sliderGet = async () => {
    try {

        const response = await api.get(`api/slider`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message || 'Ошибка входа');
    }
};
