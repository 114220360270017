import React, { useEffect, useState } from 'react';
import { Avatar, Button } from 'antd';
import { CiBookmark } from 'react-icons/ci';
import {basketClear, findProduct} from '../../../utils/utils'; // Убедитесь, что путь к `findProduct` правильный
import { FaBottleWater } from 'react-icons/fa6';
import { AlertOutlined, DollarOutlined, UserOutlined } from '@ant-design/icons';
import { MdOutlineCountertops } from 'react-icons/md';
import { IoLocationOutline } from 'react-icons/io5';
import { makeOrder } from '../../../api/OrderApi';
import {useTranslation} from "react-i18next";
import {FcAcceptDatabase} from "react-icons/fc";
import {GiConfirmed} from "react-icons/gi"; // Убедитесь, что путь к `makeOrder` правильный

const BASE_URl = process.env.REACT_APP_URL;

const basketCard = (card, count) => (

    <div className={'w-full h-[130px] bg-white rounded-lg drop-shadow-lg flex flex-row items-center p-2 mb-4'}>
        <img
            src={BASE_URl + card.cover[0]}
            alt={card.name}
            className={'w-16 h-18 object-fit rounded-md mr-4'}
        />
        <div className={'flex flex-col flex-grow'}>
            <div className={'w-full bg-blue-100 rounded-lg p-1 mb-1 flex items-center'}>
                <FaBottleWater className={'text-blue-700 mr-2 '} />
                <span className={'text-gray-800 font-semibold text-[12px]'}>{card.name}</span>
            </div>
            <div className={'w-full bg-green-100 rounded-lg p-1 flex items-center'}>
                <DollarOutlined className={'text-green-700 mr-2'} />
                <span className={'text-gray-800 font-semibold text-[12px]'}>1 dona {card.price} so`m</span>
            </div>
            <div className={'w-full mt-2 bg-green-100 rounded-lg p-1 flex items-center'}>
                <MdOutlineCountertops className={'text-green-700 mr-2'} />
                <span className={'text-gray-800 font-semibold text-[12px]'}>{count} x {card.price} = {card.price * count} so`m</span>
            </div>
        </div>
    </div>
);

function UserBasket({ products }) {
    const basket = JSON.parse(localStorage.getItem('basket')) || { products: [] };
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const [total, setTotal] = useState(0);
    const [isOffer, setIsOffer] = useState(false);
    const [address, setAddress] = useState(user.address || 'unknown');
    const [count, setCount] = useState(0);
    const { t } = useTranslation();
    useEffect(() => {
        let newTotal = 0;
        let totalCount = 0;
        basket.products.forEach(value => {
            const currentProduct = findProduct(products, value.product_id);
            if (currentProduct) {
                newTotal += value.count * parseFloat(currentProduct.price);
                totalCount += value.count;
            }
        });
        setTotal(newTotal);
        setCount(totalCount);
    }, [basket.products, products]);

    const toggle = () => {
        setIsOffer(!isOffer);
    };

    const acceptOrder = async () => {
        const data = await makeOrder({ address: user.address, products: basket.products });
        if (data.message === 'Order created successfully'){
            basketClear()
            window.location.reload()
        }
    };

    return (
        <div className={'w-full bg-white/30 rounded-lg h-fit flex flex-col overflow-scroll p-2 scrollable mt-4'}>
            <div className={'w-full h-fit'}>
                {isOffer && (
                    <div className={'w-full h-fit bg-white/50 rounded-lg p-2'}>
                        <div className={'flex flex-row bg-orange-500/50 rounded-l-full text-white items-center'}>
                            <Avatar className={'mr-4'} icon={<UserOutlined />} />
                            {user.fullname}
                        </div>
                        <div className={'flex flex-row bg-red-700/70 mt-2 rounded-md text-white items-center'}>
                            <Avatar shape={'square'} className={'mr-4'} icon={<IoLocationOutline />} />
                            {user.address === null ? 'нажмите что бы указать адресс' : user.address}
                        </div>
                        <div className={'flex flex-row bg-blue-700/70 mt-2 rounded-md text-white items-center'}>
                            <Avatar shape={'square'} className={'mr-4'} icon={<FaBottleWater />} />
                            Количество боклашок: {user.client_card?.bottles_count || 0} шт.
                        </div>
                        <div className={`flex flex-row ${count > (user.client_card?.bottles_count || 0 ) ? 'bg-red-400' : 'bg-green-400'} mt-2 rounded-md text-white items-center p-2`}>
                            <Avatar
                                shape={'square'}
                                className={'mr-4 flex-shrink-0'}
                                icon={count > (user.client_card?.bottles_count || 0 ) ? <AlertOutlined />  : <GiConfirmed/>}
                                style={{ width: 40, height: 40 }}
                            />
                            <span>
                                {count > (user.client_card?.bottles_count || 0)
                                    ? t('bottle_alert_danger')
                                    : t('bottle_alert')}
                            </span>
                        </div>
                    </div>
                )}
                <div className={'w-full mt-4 h-[300px] overflow-scroll scrollable'}>
                    {basket.products.map(value => {
                        const currentProduct = findProduct(products, value.product_id);
                        if (currentProduct) {
                            return basketCard(currentProduct, value.count);
                        }
                        return null;
                    })}
                    <div className={'mt-2 font-semibold text-green-700'}>{t('total')}: ${total.toFixed(2)}</div>
                </div>
            </div>
            {basket.products.length > 0 &&
            <Button
                onClick={!isOffer ? toggle : acceptOrder}
                className={'w-[80%] bg-red-400 text-white absolute bottom-4 left-[10%]'}
                icon={<CiBookmark />}
            >
                {isOffer ? t('accept') : t('go_accept')}
            </Button>
            }
        </div>
    );
}

export default UserBasket;
