import React, { useState } from 'react';
import { Button } from 'antd';
import {useTranslation} from "react-i18next";

export default function NewsCard({ content }) {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const BASE_URL = process.env.REACT_APP_URL;
    const { t } = useTranslation();
    const showFullScreen = () => {
        console.log('open');
        setIsFullScreen(true);
    };

    const hideFullScreen = (e) => {
        console.log('close');
        e.stopPropagation();
        setIsFullScreen(false);
    };

    return (
        <>
            <div className="w-full h-[180px] relative mb-4 cursor-pointer" onClick={showFullScreen}>
                <div
                    className="w-full h-full bg-cover bg-center rounded-lg overflow-hidden"
                    style={{ backgroundImage: `url(${BASE_URL + content?.cover})` ,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}
                >
                    <div className="w-full h-full bg-black/50 p-4 flex flex-col justify-end">
                        <div className="text-white text-sm font-semibold mb-1">
                            {content?.brief_description.length > 20 ? `${content?.brief_description.substring(0, 20)}...` : content?.brief_description}
                        </div>
                        <div className="text-white text-xs">{new Date(content?.created_at).toLocaleString()}</div>
                    </div>
                </div>
            </div>
            {isFullScreen && (
                <div
                    className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-black/70 p-4 transition-opacity duration-300 ease-in-out"
                    onClick={hideFullScreen}
                >
                    <div
                        className="relative w-full max-w-4xl h-[200px] bg-cover bg-center rounded-lg overflow-hidden mb-4 shadow-lg"
                        style={{backgroundImage: `url(${BASE_URL + content?.cover})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'}}
                        onClick={(e) => e.stopPropagation()} // Prevent background click from closing
                    >
                        <Button
                            onClick={hideFullScreen}
                            className="absolute top-4 right-4 bg-red-500 hover:bg-red-600 text-white border-none"
                        >
                            {t('close')}
                        </Button>
                    </div>
                    <div className="text-lg font-semibold mb-2 text-white text-center">{content?.description}</div>
                    <div className="text-gray-300 text-sm mb-4 text-center">{new Date(content?.created_at).toLocaleString()}</div>
                    <div className="text-white mb-4 text-center">{content?.type}</div>
                </div>
            )}
        </>
    );
}
