import React from "react";
import NewsCard from "./Items/NewsCard";
import {getNewsByLanguage} from "../../utils/utils";

export default function News({ news }) {
    return (
        <div className={'w-full h-full overflow-scroll scrollable p-4'}>
            {getNewsByLanguage(news).map((item, index) => (
                <NewsCard key={index} content={item} />
            ))}
        </div>
    );
}
