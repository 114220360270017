import React, {useEffect, useState} from "react";
import {Divider, Drawer, Menu, message} from "antd";
import SwipeableCarousel from "./components/MainSwiper/SwipeableCarousel";
import logo from './logo.svg';
import './App.css';
import background_main from './assets/img/backgrounds/background_mainbackground_main.png'
import drawer_bg from './assets/img/backgrounds/drawer_bg.png'
import profile_button from './assets/img/buttons/profile_button.png'
import tools_button_bg from './assets/img/buttons/tools_button_bg.png'
import home_button from './assets/img/buttons/home_button.png'
import setting_button from './assets/img/buttons/setting_button.png'
import order_button_main from './assets/img/buttons/order_button_main.png'
import bottle_20L from './assets/img/items/bottle_20.png'
import Profile from "./components/Profile/Profile";
import Home from "./components/Home/Home";
import Orders from "./components/Orders/Orders";
import Auth from "./components/Auth/Auth";
import uzbekistan from './assets/img/flags/uzbekistan.png'
import russia from './assets/img/flags/russia.png'
import english from './assets/img/flags/united-states.png'
import Dropdown from "antd/es/dropdown/dropdown";
import {productsGet} from "./api/ProductsApi";
import {Basket, versionCheck} from "./utils/utils";
import {useTelegram} from "./hooks/TelegramHooks";
import {login, loginTelegram} from "./api/AuthApi";
import './i18n';
import { useTranslation } from "react-i18next";
import Settings from "./components/Settings/Settings";
function App() {
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('bottom');

    const [page, setPage] = useState(null);
    const [auth, setAuth] = useState(false);
    const storedLang = localStorage.getItem('lang');
    const initialLang = storedLang ? JSON.parse(storedLang).key : "1";
    const [selectedKey, setSelectedKey] = useState(initialLang); // Установите '1', '2' или '3' для выбора по умолчанию
    const [categories, setCategories] = useState([]);
    const [bottleProducts, setBottleProducts] = useState([]);
    const [filtredBottleProducts, setFiltredBottleProducts] = useState([]);
    const { i18n } = useTranslation();
    const {tg} = useTelegram();
    const { t } = useTranslation();

    const handleMenuClick = (e) => {
        setSelectedKey(e.key);
        switch (e.key){
            case 1:
                i18n.changeLanguage('uz')
                break
            case 2:
                i18n.changeLanguage('ru')
                break
            case 3:
                i18n.changeLanguage('en')
                break
        }
        localStorage.setItem(
            'lang',
            JSON.stringify({
                key: e.key,
                code: e.key === '1' ? 'uz' : e.key === '2' ? 'ru' : 'en'
            })
        );
        window.location.reload()
    };

    const filterBottleProducts = (categories) => {
        return categories
            .filter(category => category.type && category.type.type === 'bottle')
            .map(category => category.products)
            .flat();
    };


    const menu = (
        <Menu
            onClick={handleMenuClick}
            selectedKeys={[selectedKey || '1']} // Убедитесь, что selectedKey корректно устанавливается
        >
            <Menu.Item key="1">
                <img src={uzbekistan} alt="Uzbekistan" width="24" height="24"/>
            </Menu.Item>
            <Menu.Item key="2">
                <img src={russia} alt="Russia" width="24" height="24"/>
            </Menu.Item>
            <Menu.Item key="3">
                <img src={english} alt="English" width="24" height="24"/>
            </Menu.Item>
        </Menu>
    );

    useEffect(()=>{},[selectedKey])
    const handleFlag = () => {
        switch (selectedKey) {
            case '1':
                return <img src={uzbekistan} alt="Uzbekistan" width="24" height="24"/>
            case '2':
                return <img src={russia} alt="Russia" width="24" height="24"/>
            case '3':
                return <img src={english} alt="English" width="24" height="24"/>
        }
    }
    const items = [
        <div style={{
            backgroundImage: `url(${bottle_20L})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%'
        }}></div>,
        <div style={{
            backgroundImage: `url(${bottle_20L})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%'
        }}></div>,
    ];

    const checkAuth = async () => {
        if (localStorage.getItem('phoneNumber') || localStorage.getItem('telegram_id')) {
            try {
                const data =      tg?.initDataUnsafe?.user === undefined ? await login({
                    phoneNumber: localStorage.getItem('phoneNumber'),
                    password: localStorage.getItem('enc')
                }) : await loginTelegram({
                    telegram_id: tg?.initDataUnsafe?.user.id,
                    password: localStorage.getItem('enc')
                });
                localStorage.setItem('user',JSON.stringify(data.user))
                message.success(t('auto_sign'))
            } catch (e) {
                console.log(e)
            }
        }
    }
    useEffect(() => {
        tg.expand();
        tg.disableVerticalSwipes();
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        console.log(screenWidth)
        console.log(screenHeight)
        versionCheck()
        checkAuth()
        if (!localStorage.getItem('drawer_page')) {
            localStorage.setItem('drawer_page', 'main')
        }
        if (!localStorage.getItem('lang')) {
            localStorage.setItem(
                'lang',
                JSON.stringify({
                    key: "1",
                    code: 'uz'
                })
            );
        }
        if (!localStorage.getItem('token')) {
            setAuth(true)
        }
        Basket()
        fetchCategories()
    }, []);

    const fetchCategories = async () => {
        const data = await productsGet();
        const filteredBottleProducts2 = filterBottleProducts(data);
        setBottleProducts(filteredBottleProducts2);
        setCategories(data)

        setFiltredBottleProducts([])
        const newFiltered = []
        filteredBottleProducts2.map(value => {
            newFiltered.push(value)
        })
        setFiltredBottleProducts(newFiltered)
        console.log(newFiltered)

    }
    useEffect(() => {
        const preventDefault = (e) => {
            if (!e.target.closest('.scrollable')) {
                e.preventDefault();
            }
        };

        document.addEventListener('touchmove', preventDefault, {passive: false});

        return () => {
            document.removeEventListener('touchmove', preventDefault);
        };

    }, []);

    const contentHadler = () => {
        const page_name = localStorage.getItem('drawer_page')
        switch (page_name) {
            case 'profile':
                return <Profile/>
            case 'home':
                return <Home categories={categories}/>
            case 'order':
                return <Orders products={categories}/>
            case 'setting':
                return <Settings/>
            default:
                return <div>error</div>
        }
    }
    const showDrawer = () => {
        const drawer_page = localStorage.getItem('drawer_page');
        setPage(drawer_page);

        setOpen(true);
    };

    const pageHandler = (name) => {
        localStorage.setItem('drawer_page', name)

    };

    const onClose = () => {
        setOpen(false);
    };

    const authHandler = () => {
        return auth ? <Auth/> : <>
            <div className={'w-screen h-screen relative overflow-hidden'} style={
                {
                    backgroundImage: `url(${background_main})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }
            }>
                <div className={'absolute left-4 top-4'}>
                    <Dropdown overlay={menu}>
                        <div className={'w-[50px] h-[50px] ml-2 cursor-pointer'}>
                            {handleFlag()}
                        </div>
                    </Dropdown>
                </div>
                <div className={`top-[25%] absolute w-full -left-[9px] ${window.innerHeight <= 667 && 'top-[17%]'}`}>
                    <SwipeableCarousel a={filtredBottleProducts} items={filtredBottleProducts}/>
                </div>
                <div onClick={() => {
                    pageHandler('profile');
                    showDrawer();
                }} className={'absolute w-[62px] h-[62px] right-4 top-4 cursor-pointer'}
                     style={{backgroundImage: `url(${profile_button})`}}></div>
                <div className={`absolute w-[100%] h-[230px]    flex flex-row justify-between ${ window.innerHeight <= 667 ? ' -bottom-[61px] ' :' bottom-0 '}`} style={
                    {
                        backgroundImage: `url(${tools_button_bg})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }
                }>
                    <div onClick={() => {
                        pageHandler('home');
                        showDrawer();
                    }} className={'w-[72px] h-[72px] mt-16 ml-8'} style={{backgroundImage: `url(${home_button})`}}/>
                    <div onClick={() => {
                        pageHandler('order');
                        showDrawer();
                    }} className={'w-[72px] h-[92px] mt-4 ml-4'}
                         style={{backgroundImage: `url(${order_button_main})`}}/>
                    <div onClick={() => {
                        pageHandler('setting');
                        showDrawer();
                    }} className={'w-[72px] h-[72px] mt-16 mr-8'} style={{backgroundImage: `url(${setting_button})`}}/>
                </div>
                <Drawer
                    style={
                        localStorage.getItem('drawer_page') === 'profile' ?
                            {
                                borderBottomRightRadius: '20px',
                                borderBottomLeftRadius: '20px',
                                backgroundImage: `url(${drawer_bg})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                padding: 0
                            } : {
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                                backgroundImage: `url(${drawer_bg})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                padding: 0
                            }
                    }
                    height={localStorage.getItem('drawer_page') === 'profile' ? '40vh' : '75vh'}
                    title={t(localStorage.getItem('drawer_page'))}
                    placement={localStorage.getItem('drawer_page') === 'profile' ? 'top' : placement}
                    closable={false}
                    onClose={onClose}
                    open={open}
                    key={placement}
                >
                    {contentHadler()}
                </Drawer>
            </div>
        </>
    }

    return (authHandler());
}

export default App;
