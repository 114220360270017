import React from "react";
import {useTranslation} from "react-i18next";
import {Avatar, Button, Input, Typography} from "antd";
import {PhoneOutlined, SettingOutlined, UserDeleteOutlined, UserOutlined} from "@ant-design/icons";
import uzbekistan from '../../assets/img/flags/uzbekistan.png'
import russia from '../../assets/img/flags/russia.png'
import english from '../../assets/img/flags/united-states.png'
import {IoLocationOutline} from "react-icons/io5";
import {PiCarProfileThin} from "react-icons/pi";
import {AiOutlineProfile} from "react-icons/ai";

export default function Settings() {
    const {t} = useTranslation();
    const { i18n } = useTranslation();
    const  user = JSON.parse(localStorage.getItem('user'))
    const handleLanguage = (id)=>{
        switch (id){
            case 1:
                i18n.changeLanguage('uz')
                localStorage.setItem(
                    'lang',
                    JSON.stringify({
                        key: "1",
                        code: 'uz'
                    })
                );
                break
            case 2:
                i18n.changeLanguage('ru')
                localStorage.setItem(
                    'lang',
                    JSON.stringify({
                        key: "2",
                        code: 'ru'
                    })
                );
                break
            case 3:
                i18n.changeLanguage('en')
                localStorage.setItem(
                    'lang',
                    JSON.stringify({
                        key: "3",
                        code: 'en'
                    })
                );
                break
        }

    }
    return <div className={'w-full h-full'}>
        <div className={'flex flex-row w-full bg-white/80 rounded-lg p-2'}>
            <SettingOutlined/> <Typography className={'ml-2 font-bold'}>  {t('setting')}</Typography>
        </div>

        <div className={'flex flex-row items-center justify-center flex-wrap bg-white/60 rounded-lg mt-2 p-4'}>
            <div onClick={ ()=>handleLanguage(1)} className={'flex flex-col w-[70px] h-[70px] bg-white rounded-md p-1 justify-center items-center'}>
                <img className={'w-[42px] h-[42px]'} src={uzbekistan}/>
                <Typography className={'text-[10px] bg-blue-500 text-white rounded pl-1 pr-1'}> O`zbekcha</Typography>
            </div>
            <div onClick={ ()=>handleLanguage(2)} className={'flex flex-col w-[70px] h-[70px] ml-2 bg-white rounded-md p-1 justify-center items-center'}>
                <img className={'w-[42px] h-[42px]'} src={russia}/>
                <Typography className={'text-[10px] bg-blue-500 text-white rounded pl-1 pr-1'}> Русский</Typography>
            </div>
            <div onClick={ ()=>handleLanguage(3)} className={'flex flex-col w-[70px] h-[70px] ml-2 bg-white rounded-md p-1 justify-center items-center'}>
                <img className={'w-[42px] h-[42px]'} src={english}/>
                <Typography className={'text-[10px] bg-blue-500 text-white rounded pl-1 pr-1'}> English </Typography>
            </div>

            <div className={'w-full h-[50px] mt-4 flex flex-row items-center justify-between bg-orange-500/30 rounded-l-full rounded-r-lg pr-1'}>
                <Avatar className={'w-[15%] h-[50px]'} icon={<UserOutlined/>}/>
                <Input  placeholder={user.fullname} style={{background:'transparent',border:"solid white",color:"#383838",fontWeight:'bold'}} className={'w-[78%]'}  type={"text"} />
            </div>
            <div className={'w-full h-[50px] mt-4 flex flex-row items-center justify-between bg-red-500/30 rounded-lg pr-1'}>
                <Avatar shape={'square'} className={'w-[15%] h-[50px]'} icon={<IoLocationOutline/>}/>
                <Input  placeholder={user.address} style={{background:'transparent',border:"solid white",color:"#383838",fontWeight:'bold'}} className={'w-[78%]'}  type={"text"} />
            </div>
            <div className={'w-full h-[50px] mt-4 flex flex-row items-center justify-between bg-green-500/30 rounded-lg pr-1'}>
                <Avatar shape={'square'} className={'w-[15%] h-[50px]'} icon={<PhoneOutlined/>}/>

                <Input  placeholder={user.phoneNumber} style={{background:'transparent',border:"solid white",color:"#383838",fontWeight:'bold'}} className={'w-[78%]'}  type={"text"} />
            </div>
            <Button onClick={()=>{
            localStorage.clear();
            window.location.reload()
            }
            } className={'mt-2 bg-red-600 text-white font-bold'} icon={<UserDeleteOutlined/>} >{t('sign_out')} </Button>
            <Button onClick={()=>{window.location.reload()}}  className={'bg-green-600 mt-4 text-white'}>{t('updateProfile')}</Button>

        </div>


    </div>
}
