import React from "react";
import {Avatar, Typography} from "antd";
import {BankOutlined, DollarOutlined, PhoneOutlined, UserOutlined} from "@ant-design/icons";
import {IoLocationOutline} from "react-icons/io5";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Pagination } from 'swiper/modules';
import './style.css';
import {useTranslation} from "react-i18next";

export default function Profile(){
    const user =JSON.parse(localStorage.getItem('user'));
    const { t } = useTranslation();

    const items = [
        <div className={'w-full h-full p-2'}>
            <div className={'flex flex-row w-full h-fit items-center bg-[#f78a42]/50 rounded-l-full rounded-r-lg'}>
                <Avatar className={'w-[42px] h-[42px]'} style={{ backgroundColor: '#f78a42' }} icon={<UserOutlined />} />
                <Typography className={'ml-4 font-bold text-white'}> {user.fullname}</Typography>
            </div>
            <div className={'flex flex-row w-full h-fit items-center mt-4 bg-[#3584cb]/50 rounded'}>
                <Avatar shape={'square'} className={'w-[42px] h-[42px]'} style={{ backgroundColor: '#3584cb' }} icon={<PhoneOutlined />} />
                <Typography className={'ml-4 font-bold text-white'}> + {user.phoneNumber} </Typography>
            </div>
            <div className={'flex flex-row w-full h-fit items-center mt-4 bg-[#1dd204]/50 rounded'}>
                <Avatar shape={'square'} className={'w-[42px] h-[42px]'} style={{ backgroundColor: '#1dd204' }} icon={<DollarOutlined />} />
                <Typography className={'ml-4 font-bold text-white'}> {t('your_cashback')} : {user.client_card.cashback} </Typography>
            </div>
            <div className={'flex flex-row w-full h-fit items-center mt-4 bg-[#d9034b]/30 rounded'}>
                <Avatar shape={'square'} className={'w-[42px] h-[42px]'} style={{ backgroundColor: '#d9034b' }} icon={<IoLocationOutline />} />
                <Typography className={'ml-4 font-bold text-white'}> {t('your_adress')} : {user.address} </Typography>
            </div>
        </div>,
        <div>

        </div>

    ]
    return <div className={'w-full h-full flex flex-col bg-white/30 rounded-lg backdrop-blur-md'}>
        <Swiper pagination={true} modules={[Pagination]} className="mySwiper2 rounded-xl bg-transparent">
            {items.map((value ,index)=>   <SwiperSlide   key={index}>{value}</SwiperSlide>)}
        </Swiper>

    </div>

}
