// src/authService.js
import api from './api';

// Функция для регистрации
export const register = async (userData) => {
    try {
        const response = await api.post('api/register', userData);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : 'Ошибка регистрации');
    }
};

// Функция для входа
export const login = async (credentials) => {
    try {
        const response = await api.post('api/login', credentials);
        localStorage.setItem('phoneNumber',credentials.phoneNumber)
        localStorage.setItem('enc',credentials.password)
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : 'Ошибка входа');
    }
};

export const loginTelegram = async (credentials) => {
    try {
        const response = await api.post('api/telegram-login', credentials);
        localStorage.setItem('telegram_id',credentials.phoneNumber)
        localStorage.setItem('enc',credentials.password)
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : 'Ошибка входа');
    }
};
