import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import './SwipeableCarousel.css';
import {upDateBasket} from "../../utils/utils";
import {useTranslation} from "react-i18next";
import {message} from "antd";

const SwipeableCarousel = ({ items }) => {
    const { t } = useTranslation();
    const getCarouselItemClass = () => {
        const height = window.innerHeight;

        if (height >= 932) {
            return 'h-[373px]'; // Для экранов выше или равных 896px
        } else if (height >= 986) {
            return 'h-[373px]'; // Для экранов в диапазоне 932px
        } else if (height >= 844) {
            return 'h-[335px]'; // Для экранов в диапазоне 844px и выше
        } else if (height >= 768) {
            return 'h-[335px]'; // Для экранов в диапазоне 768px и выше
        } else if (height >= 640) {
            return 'h-[344px]'; // Для экранов в диапазоне 640px и выше
        } else {
            return 'h-[250px]'; // Для экранов меньше 640px
        }
    };

    const BASE_URL = process.env.REACT_APP_URL;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [swipeClass, setSwipeClass] = useState('');
    const [showDetails, setShowDetails] = useState(false);

    const handleSwipedLeft = () => {
        setSwipeClass('swipe-left');
        setShowDetails(false);
        setTimeout(() => {
            setSwipeClass('');
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 300);
    };

    const handleSwipedRight = () => {
        setSwipeClass('swipe-right');
        setShowDetails(false);
        setTimeout(() => {
            setSwipeClass('');
            setCurrentIndex((prevIndex) =>
                prevIndex === 0 ? items.length - 1 : prevIndex - 1
            );
        }, 300);
    };

    useEffect(() => {

    }, [items]);

    const handlers = useSwipeable({
        onSwipedLeft: handleSwipedLeft,
        onSwipedRight: handleSwipedRight,
    });

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const add = (id)=>{
        upDateBasket('add',id,1)
        message.success(t('add'));
    }
    const remove = (id)=>{
        upDateBasket('remove',id,1)
        message.success(t('remove'));
    }

    const currentItem = items[currentIndex];

    return (
        <div {...handlers} className="carousel-container ">
            <div className={`carousel-item  ${getCarouselItemClass()} ${swipeClass}`} onClick={toggleDetails}>
                <div
                    style={{
                        backgroundImage: `url(${BASE_URL}${currentItem?.cover[0]})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '100%'
                    }}
                ></div>
                <div className={`button-container ${showDetails ? 'show' : ''} z-[20] -left-[58px]`}>
                    <button className="button" onClick={(e) => { e.stopPropagation(); remove(currentItem?.id) }}>-</button>
                    <button className="button" onClick={(e) => { e.stopPropagation(); add(currentItem?.id) }}>+</button>
                </div>
                <div className={`info-container  ${showDetails ? 'show' : ''}`}>
                    <h2>{currentItem?.name}</h2>
                    <p>{currentItem?.description}</p>
                    <p>{currentItem?.price}</p>
                </div>
            </div>
        </div>
    );
};

export default SwipeableCarousel;
