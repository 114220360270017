import { useEffect, useState } from "react";
import { message } from "antd";

const tg = window.Telegram.WebApp;

export function useTelegram() {
    const onClose = () => {
        tg.close();
    };

    // Функция для запроса номера телефона
    const requestPhone = () => {
        tg.MainButton.text = "Share Phone Number";
        tg.MainButton.show();
        tg.MainButton.onClick(() => {
            tg.MainButton.hide();
            tg.sendData("Requesting phone number");
            tg.requestContact((result) => {
                const phoneNumber = result.phone_number || result.contact?.phone_number || result.user?.phone_number;
                if (phoneNumber) {
                    message.success(`Phone number received: ${phoneNumber}`);
                } else {
                    message.error(`Failed to retrieve phone number ${JSON.stringify(result)}`);
                }
            });
        });
    };

    const requestContact = (callback) => {
        tg.requestContact((result) => {
            if (result && result.contact) {
                callback(result);
            } else {
                message.error(`Failed to retrieve contact information ${JSON.stringify(result)}`);
            }
        });
    };

    return {
        onClose,
        requestPhone,
        tg,
        user: tg?.initDataUnsafe?.user,
        queryId: tg.initDataUnsafe?.query_id,
        telegramId: tg.initDataUnsafe?.user?.id,
        requestContact
    };
}
