import api from './api';
import {message} from "antd";

export const makeOrder = async (data) => {
    try {
        const response = await api.post('api/makeOrder', data);
        return response.data;
    } catch (error) {
        console.log(error)
        throw new Error(error.response ? error.response.data.message : 'Ошибка  оформления заказа');
        message.error('ошибка оформления заказа')
    }
};
