import api from './api';
export const newsGet = async () => {
    try {
        const lang = JSON.parse( localStorage.getItem('lang') )
        const response = await api.get(`api/news`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message || 'Ошибка входа');
    }
};
