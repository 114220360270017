import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationUz from './locales/uz/translation.json';
import translationRu from './locales/ru/translation.json';
import translationEn from './locales/en/translation.json';

// The translations
const resources = {
    uz: {
        translation: translationUz
    },
    ru: {
        translation: translationRu
    },
    en: {
        translation: translationEn
    }
};

// Get language from localStorage, with fallback to 'uz'
const storedLang = localStorage.getItem('lang');
const defaultLang = 'uz';
let initialLang = defaultLang;

try {
    const parsedLang = JSON.parse(storedLang);
    if (parsedLang && parsedLang.code) {
        initialLang = parsedLang.code;
    }
} catch (error) {
    console.warn('Failed to parse language from localStorage, defaulting to', defaultLang);
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: initialLang,
        fallbackLng: defaultLang,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
