import React, {useEffect, useState} from "react";
import {Tabs, Card, Button, Typography} from "antd";
import {FaDollarSign, FaMapMarkerAlt} from "react-icons/fa";
import {FaBottleWater} from "react-icons/fa6";
import {MdOutlineArrowBack, MdOutlineDateRange} from "react-icons/md";
import UserBasket from "./items/UsetBasket";
import {format} from 'date-fns';
import {useTranslation} from "react-i18next"; // Используем библиотеку для форматирования даты

const {TabPane} = Tabs;

const style = 'flex flex-row items-center mb-2';
const iconStyle = 'text-xl mr-2';

// Преобразование данных заказа для отображения
const parseOrderData = (order) => ({
    key: order.id,
    orderNumber: order.id.toString().padStart(3, '0'),
    customer: `User ${order.user_id}`, // Замените на реальное имя клиента, если доступно
    status: order.status,
    date: format(new Date(order.date), 'dd MMM yyyy'), // Форматирование даты
    amount: `$${parseFloat(order.total).toFixed(2)}`,
    address: order.address || 'Not specified',
    bottles: (order.description.match(/soni : (\d+)/) || [])[1] || 0,
});

// Функция для получения и обработки данных из localStorage
const useOrderData = () => {
    const [currentOrders, setCurrentOrders] = useState([]);
    const [archivedOrders, setArchivedOrders] = useState([]);

    useEffect(() => {
        const storedOrders = JSON.parse(localStorage.getItem('user')).orders || [];

        const current = storedOrders.filter(order =>
            order.status === 'on hold' || order.status === 'pending'
        ).map(parseOrderData);

        const archived = storedOrders.filter(order =>
            order.status === 'completed' || order.is_denied === 1
        ).map(parseOrderData);

        setCurrentOrders(current);
        setArchivedOrders(archived);
    }, []);

    return {currentOrders, archivedOrders};
};

function OrderCard({order}) {
    const {t} = useTranslation();
    return (

        <Card
            title={`Order #${order.orderNumber}`}
            style={{width: '100%', marginBottom: '16px', borderRadius: '8px', border: '1px solid #d9d9d9'}}
            extra={<span
                style={{color: order.status === 'completed' ? 'green' : 'red'}}><strong>Status:</strong> {order.status}</span>}
        >
            <p className={style}>
                <FaDollarSign className={`${iconStyle} text-green-500`}/>
                <strong>{t('amount')}:</strong> {order.amount}
            </p>
            <p className={style}>
                <FaMapMarkerAlt className={`${iconStyle} text-red-400`}/> <strong>{t('address')}:</strong> {order.address}
            </p>
            <p className={style}>
                <FaBottleWater className={`${iconStyle} text-blue-500`}/> <strong>{t('bottles')}:</strong> {order.bottles}
            </p>
            <p className={style}>
                <MdOutlineDateRange className={`${iconStyle} text-indigo-400`}/> <strong>{t('date')}:</strong> {order.date}
            </p>
        </Card>
    );
}

export default function Orders({products}) {
    const [isBasket, setIsBasket] = useState(true);
    const {currentOrders, archivedOrders} = useOrderData();
    const {t} = useTranslation();
    const toggleBasket = () => {
        setIsBasket(!isBasket);
    };

    return (
        <div className="w-full h-full p-4 overflow-hidden flex flex-col items-center">
            <div className={'flex flex-row items-center mb-4'}>
                <Typography className={` ${isBasket ? 'bg-red-600' : 'bg-blue-700'} p-1 rounded-lg text-white`}>
                    {isBasket ? t('basket') : t('order')}
                </Typography>
                <Button className={'ml-4'} onClick={toggleBasket} icon={<MdOutlineArrowBack/>}>
                    {isBasket ? t('order') : t('basket')}
                </Button>
            </div>
            {isBasket ? (
                <UserBasket products={products}/>
            ) : (
                <Tabs
                    className={'w-full'}
                    defaultActiveKey="1"
                    tabPosition="top"
                    centered
                >
                    <TabPane tab={t('current_orders')} key="1">
                        <div
                            className="max-h-[calc(100vh-200px)] overflow-y-auto scrollable scrollbar-thumb-gray-900 scrollbar-track-gray-100">
                            {currentOrders.map(order => (
                                <OrderCard key={order.key} order={order}/>
                            ))}
                        </div>
                    </TabPane>
                    <TabPane tab={t('archived_orders')} key="2">
                        <div className="max-h-[calc(100vh-200px)] overflow-y-auto scrollable scrollbar">
                            {archivedOrders.map(order => (
                                <OrderCard key={order.key} order={order}/>
                            ))}
                        </div>
                    </TabPane>
                </Tabs>
            )}
        </div>
    );
}
