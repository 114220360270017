import { message } from "antd";
import {id} from "date-fns/locale";
const APP_VERSION = '1'
// Инициализация корзины в localStorage, если её ещё нет
export const Basket = () => {
        if (!localStorage.getItem('basket')) {
                localStorage.setItem('basket', JSON.stringify({ "products": [] }));
        }
};
export const basketClear = ()=>{
        localStorage.setItem('basket', JSON.stringify({ "products": [] }))
}

// Обновление корзины
export const upDateBasket = (type, id, count) => {

        const basket = JSON.parse(localStorage.getItem('basket'));

        // Найти продукт по id
        const productIndex = basket.products.findIndex(product => product.product_id === id);

        switch (type) {
                case 'add':
                        if (productIndex !== -1) {
                                // Продукт найден, увеличиваем его количество
                                basket.products[productIndex].count += count;
                        } else {
                                // Продукт не найден, добавляем новый продукт в корзину
                                basket.products.push({ product_id: id, count: count });
                        }
                        break;
                case 'remove':
                        if (productIndex !== -1) {
                                // Продукт найден, уменьшаем его количество
                                basket.products[productIndex].count -= count;
                                if (basket.products[productIndex].count <= 0) {
                                        // Если количество меньше или равно 0, удаляем продукт из корзины
                                        basket.products.splice(productIndex, 1);
                                }
                        } else {
                                message.error('Product not found in the basket');
                        }
                        break;
                default:
                        message.error('Cannot find this type');
                        return;
        }

        // Обновляем корзину в localStorage
        localStorage.setItem('basket', JSON.stringify(basket));

};

export const productFindById = (id)=>{
        const basket = JSON.parse(localStorage.getItem('basket'));
        const product = basket.products[basket.products.findIndex(product => product.product_id === id)]
        return  product;
}

// Фильтр корзины (пример функции)
export const basketFilter = () => {
        const basket = JSON.parse(localStorage.getItem('basket'));

        if (basket !== null) {
                return basket.products.filter(product => product.count > 0);
        }

        return [];
};

export const findProduct = (categories, id) => {
        for (let category of categories) {
                const product = category.products.find(product => product.id === id);
                if (product) {
                        return product;
                }
        }
        return null; // Если продукт не найден
};

export const getNewsByLanguage = (news) => {
        // Извлечение языка из localStorage
        const lang = JSON.parse(localStorage.getItem('lang'));

        if (!lang || !lang.code) {
                return []; // Возвращаем пустой массив, если язык не найден или код языка отсутствует
        }

        const langCode = lang.code;

        // Фильтрация новостей по коду языка
        const filteredNews = news.filter(item => item.language.code === langCode);

        return filteredNews;
};

export const versionCheck = ()=>{
        if (localStorage.getItem('app_version') === null){
                console.log('asda222s')
                localStorage.clear()
                localStorage.setItem('app_version',APP_VERSION)
        } else if (localStorage.getItem('app_version') !== APP_VERSION){
                localStorage.clear()
                localStorage.setItem('app_version',APP_VERSION)
                console.log('asdas')
        }

}
