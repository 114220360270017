import React, { useEffect } from "react";
import Card_item from "../../../Card/Card_item";
import { Typography } from "antd";

export default function Products({ categories }) {
    useEffect(() => {
        // Если нужна логика для обновления при изменении categories, добавь её здесь
    }, [categories]);
    useEffect(()=>{},[])

    return (
        <div className="w-full h-[330px] overflow-y-scroll mt-4 scrollable p-2">
            {categories.map((category, categoryIndex) => (
                <div key={categoryIndex} className="mb-4">
                    <Typography.Title level={4}>{category.name}</Typography.Title>
                    <div className="flex flex-wrap">
                        {category.products.map((product, productIndex) => (
                            <Card_item key={productIndex} card={product} />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}
