import api from './api';

export const productsGet = async () => {
    try {
        const lang = JSON.parse(localStorage.getItem('lang'));

        if (!lang || !lang.code) {
            throw new Error('Язык не выбран или отсутствует в локальном хранилище');
        }

        const response = await api.get(`api/category-lang/${lang.code}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message || 'Ошибка входа');
    }
};
